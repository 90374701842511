import axios from "axios";
import { Customer } from "../Models/Customer";
import { CustomerDetails } from "../Models/CustomerDetails";
import { Machine } from "../Models/Machine";
import { Product } from "../Models/Product";
import { WorkOrder } from "../Models/WorkOrder";
import {
  CALCULATIONS_PATH,
  CURRENCY_PATH,
  CUSTOMER_DETAILS_PATH,
  CUSTOMER_PATH,
  DEFAULT_WORK_PRICE_PATH,
  LOGIN_PATH,
  MACHINE_PATH,
  PRODUCT_PATH,
  REFRESH_PATH,
  STATISTICS_PATH,
  USER_PATH,
  WORKORDER_PATH,
} from "../Constants/API_PATHS";
import { Statistics } from "../Models/Statistics";
import { Currency } from "../Models/Currency";
import { jwtDecode } from "jwt-decode";
import { CalculationGroup } from "../Models/CalculationGroup";

export const options = (METHOD: string, PATH: string, token: any, data?: any, query?: any, params?: any) => {
  let options;
  if (query !== undefined) {
    options = {
      method: METHOD,
      url: `${process.env.REACT_APP_API_URL}${PATH}/${query}`,
      data: data,
      params: params,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    options = {
      method: METHOD,
      url: `${process.env.REACT_APP_API_URL}${PATH}`,
      data: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
  }

  return options;
};

let token = JSON.parse(localStorage.getItem("token") || "{}");

export default class API {
  //PRODUCTS
  getProducts = async (page?: number, limit?: number, search?: string, onlyWarnings: 0 | 1 = 0) => {
    return axios
      .request(options("GET", PRODUCT_PATH, token, null, "", { page, limit, search, onlyWarnings }))
      .then((response) => response.data.productModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  postProduct = async (product: Product) => {
    return axios
      .request(options("POST", PRODUCT_PATH, token, product))
      .then((response) => response.data.productModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //NOT USED??
  getProductDetails = async () => {
    return axios
      .request(options("GET", `${PRODUCT_PATH}/productDetails/prod`, token))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  putProduct = async (products: Product) => {
    return axios
      .request(options("PUT", PRODUCT_PATH, token, products))
      .then((response) => response.data.request.body)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  deleteProduct = async (productId: number) => {
    return axios
      .request(options("DELETE", PRODUCT_PATH, token, null, productId))
      .then((response) => response.data.productModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //CALCULATIONS

  getCalculations = async (page: number, limit: number) => {
    return axios
      .request(options("GET", CALCULATIONS_PATH, token, null, "", { page, limit }))
      .then((response) => response.data.calculationGroupModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  getCalcDetails = async (calculationDetailsId: number) => {
    return axios
      .request(options("GET", CALCULATIONS_PATH, token, null, calculationDetailsId))
      .then((response) => response.data.calculationGroupModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  postCalculation = async (calculationDetails: CalculationGroup) => {
    return axios
      .request(options("POST", CALCULATIONS_PATH, token, calculationDetails))
      .then((response) => response.data.createdCalculationModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  putCalculation = async (calculationDetails: CalculationGroup) => {
    return axios
      .request(options("PUT", CALCULATIONS_PATH, token, calculationDetails, calculationDetails._stockCalculationId))
      .then((response) => response.data.request.body)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  //CUSTOMERS

  getCustomers = async (page: number, limit: number, search: string) => {
    return axios
      .request(options("GET", CUSTOMER_PATH, token, null, "", { page, limit, search }))
      .then((response) => response.data.customerModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  postCustomers = async (customer: Customer) => {
    return axios
      .request(options("POST", CUSTOMER_PATH, token, customer))
      .then((response) => response.data.customerModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  putCustomer = async (customer: Customer) => {
    return axios
      .request(options("PUT", CUSTOMER_PATH, token, customer, customer._customerId))
      .then((respomse) => respomse.data.request.body)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  putCustomerDetails = async (customer: CustomerDetails) => {
    return axios
      .request(options("PUT", CUSTOMER_DETAILS_PATH, token, customer, customer._customerId))
      .then((response) => response.data.customerDetailsModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  getCustomerDetails = async (_customerId: number) => {
    return axios
      .request(options("GET", CUSTOMER_DETAILS_PATH, token, null, _customerId))
      .then((response) => {
        return response.data.CustomerDetailsModel;
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  deleteCustomer = async (customer: Customer) => {
    return axios
      .request(options("DELETE", CUSTOMER_PATH, token, null, customer._customerId))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  //  MACHINES
  postMachine = async (machine: Machine, _customerId: number) => {
    return axios
      .request(options("POST", MACHINE_PATH, token, machine, _customerId))
      .then((response) => response.data.createdMachineModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  deleteMachine = async (machineseriesNumber: number) => {
    return axios
      .request(options("DELETE", MACHINE_PATH, token, null, machineseriesNumber))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //DEFAULTWORKPRICE
  getDefaultWorkPrice = async () => {
    return axios
      .request(options("GET", DEFAULT_WORK_PRICE_PATH, token))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  updateDefaultWorkPrice = async (defaultWorkPrice: number) => {
    return axios
      .request(options("PUT", DEFAULT_WORK_PRICE_PATH, token, { defaultWorkPrice: defaultWorkPrice }))
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //WORKORDERS

  getAllWorkOrders = async (page: number, limit: number, search: string) => {
    return axios
      .request(options("GET", WORKORDER_PATH, token, null, "", { page, limit, search }))
      .then((response) => response.data.workOrderModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  postWorkOrder = async (workOrder: WorkOrder, customerId: number) => {
    return axios
      .request(options("POST", WORKORDER_PATH, token, workOrder, customerId))
      .then((response) => response.data.workOrderModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
  putWorkOrder = async (WorkOrder: WorkOrder, justNumberSet: 0 | 1 = 0) => {
    return axios
      .request(options("PUT", WORKORDER_PATH, token, WorkOrder, WorkOrder._id, { justNumberSet }))
      .then((response) => response.data.workOrderModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  getWorkOrdersbyProduct = async (productId: number) => {
    return axios
      .request(options("GET", `${WORKORDER_PATH}/product`, token, null, productId))
      .then((response) => response.data.workOrderModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  deleteWorkOrder = async (WorkOrderId: number) => {
    return axios
      .request(options("DELETE", WORKORDER_PATH, token, null, WorkOrderId))
      .then((response) => response.data.workOrderModel)
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //USERS
  postLogin = async (name: string, password: string) => {
    return axios
      .request(options("POST", LOGIN_PATH, "", { name: name, password: password }))
      .then((response) => {
        localStorage.setItem("token", JSON.stringify(response.data.token));
        token = response.data.token;
        this.postRefresh();
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  getUser = async (name: string) => {
    return axios
      .request(options("GET", USER_PATH, "", null, name))
      .then((response) => {
        return response.data.UserModel;
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  postRefresh = async (force: boolean = false) => {
    const tokenDecoded = jwtDecode(token);
    if (tokenDecoded.exp) {
      let tokenDate = new Date(tokenDecoded.exp * 1000);
      const currentDate = new Date();

      console.log((tokenDate.getTime() - currentDate.getTime()) / (60000 * 60));
      if (force) {
        axios.request(options("POST", REFRESH_PATH, token)).then((tokenResponse) => {
          localStorage.setItem("token", JSON.stringify(tokenResponse.data.token));
          token = tokenResponse.data.token;
          const tokenResponseDecoded = jwtDecode(token);
          if (tokenResponseDecoded.exp) {
            tokenDate = new Date(tokenResponseDecoded.exp * 1000);
            setTimeout(() => {
              this.postRefresh(true);
            }, tokenDate.getTime() - currentDate.getTime() - 60000);
          }
        });
      } else {
        console.log("setledim");
        setTimeout(() => {
          this.postRefresh(true);
        }, tokenDate.getTime() - currentDate.getTime() - 60000);
      }
    }
  };

  /*  CustomerDetails_ProductCombo = async (_customerId: number) => {
    return axios
      .request(options("GET", CUSTOMER_DETAILS_PATH, null, _customerId))
      .then(async (response) => {
        const result = await axios.request(options("GET", PRODUCT_PATH));
        return { customer: response.data.CustomerDetailsModel, products: result.data.productModel };
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  }; */

  //CURRENCY
  getCurrency = async (): Promise<Currency> => {
    return axios
      .request(options("GET", CURRENCY_PATH, ""))
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };

  //STATISTICS
  getStatistics = async (year: string): Promise<Statistics> => {
    return axios
      .request(options("GET", STATISTICS_PATH, token, null, "", { year }))
      .then((data) => {
        return data.data.StatisticsModel;
      })
      .catch((error) => {
        console.error(error);
        throw error.message;
      });
  };
}

if (Object.keys(token).length !== 0) {
  const initDecodedToken = jwtDecode(token).exp;

  if (initDecodedToken) {
    if (new Date(initDecodedToken * 1000).getTime() - new Date().getTime() > 0) {
      const api = new API();
      console.log(new Date(initDecodedToken * 1000).getTime() - new Date().getTime());
      api.postRefresh();
    }
  }
}
