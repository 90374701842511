import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StokCalculation } from "../../Models/StockCalculation";
import { CalculationGroup } from "../../Models/CalculationGroup";
import useProduct from "../../hooks/useProduct";
import { API_LIMIT } from "../../Constants/ENUMS";
import { Product } from "../../Models/Product";
import ReactSelect, { SingleValue } from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import classes from "./CalculationDetailsPage.module.scss";
import { CALCULATION_DETAILS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import { useNavigate, useParams } from "react-router-dom";
import { OtherExpenses } from "../../Models/OtherExpenses";
import useCalculation from "../../hooks/useCalculation";
import { ThreeCircles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";

type RSelectOption = {
  value: Product;
  label: string;
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//filter width hallet

export const CalculationDetailsPage = () => {
  const [calculationGroup, setCalculationGroup] = useState<CalculationGroup>({
    _stockCalculationId: 0,
    currencyGeneral: 1,
    name: "",
    note: "",
    stopajnonPercentageSum: 0,
    isStopajPercentaged: false,
    KDVnonPercentageSum: 0,
    isKDVPercentaged: false,
    customsNonPercentageSum: 0,
    isCustomsPercentaged: false,
    oilsNonPercentageSum: 0,
    isOilsPercentaged: false,
    cifTotal: {
      navlun: 0,
      ordino: 0,
    },
    stockCalculations: [],
    otherExpenses: { extraWork: 0, taxi: 0, stampExpenses: 0, expenses: 0, difference: 0, essential: 0 },
  });

  const [manuelCalculation, setManuelCalculation] = useState<{
    otherExpenses: OtherExpenses;
    trBuy: number;
    trDocument: number;
    oils: number;
    kdv: number;
    stopaj: number;
    navlun: number;
    ordino: number;
    customs: number;
    other1: number;
    other2: number;
    other3: number;
  }>({
    otherExpenses: { extraWork: 0, taxi: 0, stampExpenses: 0, expenses: 0, difference: 0, essential: 0 },
    trBuy: 0,
    trDocument: 0,
    oils: 0,
    kdv: 0,
    stopaj: 0,
    navlun: 0,
    ordino: 0,
    customs: 0,
    other1: 0,
    other2: 0,
    other3: 0,
  });
  const { currencygeneral } = useSelector((state: GlobalState) => ({ currencygeneral: state.currency }));

  const [products, setProducts] = useState<Product[]>([]);

  const navigate = useNavigate();

  const { productsLoading, getAllProducts } = useProduct();

  const { calcId } = useParams<{ calcId: string }>();

  const { calculationsLoading, getCalculationDetails, updateCalculation, postCalculation } = useCalculation();

  useEffect(() => {
    console.log("hi", currencygeneral.rate);

    if (calcId && parseInt(calcId) !== 0) {
      getCalculationDetails(parseInt(calcId)).then((calcDetails) => setCalculationGroup(calcDetails));
    } else if (currencygeneral) {
      setCalculationGroup({
        ...calculationGroup,
        currencyGeneral: currencygeneral.rate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencygeneral]);

  console.log(calculationGroup.currencyGeneral);

  //Use effects for resetting when calc type is changed for each value

  //customs
  const changeCustoms = () => {
    const newCalc = {
      ...calculationGroup,
      isCustomsPercentaged: !calculationGroup.isCustomsPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          preCustoms: 0,
          preCustomsPercentage: 0,
          KKTC_KDV: calculationGroup.isKDVPercentaged ? (st.cif + st.boughtPriceAmount + st.oils) * (st.KKTC_KDV_Percentage / 100) : st.KKTC_KDV,
        };
      }),
    };

    reCalculateTotalsWithNewCif_BoughtPrices(newCalc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //oils
  const changeOils = () => {
    const newCalc = {
      ...calculationGroup,
      isOilsPercentaged: !calculationGroup.isOilsPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          oils: 0,
          oilsPercentage: 0,
          KKTC_KDV: calculationGroup.isKDVPercentaged ? (st.cif + st.boughtPriceAmount + st.preCustoms) * (st.KKTC_KDV_Percentage / 100) : st.KKTC_KDV,
        };
      }),
    };
    reCalculateTotalsWithNewCif_BoughtPrices(newCalc);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //stopaj
  const changeStopaj = () => {
    setCalculationGroup({
      ...calculationGroup,
      stopajnonPercentageSum: 0,
      isStopajPercentaged: !calculationGroup.isStopajPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          stopaj: 0,
          stopajPercentage: 0,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //KDV
  const changeKDV = () => {
    setCalculationGroup({
      ...calculationGroup,
      KDVnonPercentageSum: 0,
      isKDVPercentaged: !calculationGroup.isKDVPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          KKTC_KDV_Percentage: 0,
          KKTC_KDV: 0,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const totalBoughtPriceAmount = useMemo(() => {
    return calculationGroup.stockCalculations.map((st) => st.boughtAmount * st.boughtPriceAmount).reduce((a, b) => a + b, 0);
  }, [calculationGroup.stockCalculations]);

  const handleSelectedOptionChange = (newSelectedOption: SingleValue<RSelectOption>) => {
    const newCalculation: StokCalculation = {
      _calcId: "",
      cif: 0,
      oils: 0,
      otherExpensesPercentageShared: 0,
      oilsPercentage: 0,
      stopajPercentage: 0,
      KKTC_KDV_Percentage: 0,
      preCustomsPercentage: 0,

      currency: calculationGroup.currencyGeneral,
      boughtPriceAmount: 0,
      pricePerAmount: 0,
      boughtAmount: 1,
      preCustoms: 0,
      KKTC_KDV: 0,
      salesListPercentage: 60,
      stopaj: 0,

      _productId: newSelectedOption?.value._productId ?? 0,
      productName: newSelectedOption?.value.name ?? "",
      productCode: newSelectedOption?.value.code ?? "",
      productBrand: newSelectedOption?.value.brand ?? "",
      productNote: newSelectedOption?.value.note ?? "",

      status: "OPEN",
    };

    setCalculationGroup({
      ...calculationGroup,
      stockCalculations: [...calculationGroup.stockCalculations, newCalculation],
    });
  };

  const timerRef = useRef<number | undefined>(undefined);

  const searchProducts = useCallback(
    (search: string) => {
      if (search.length > 2) {
        if (timerRef.current) window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          getAllProducts(1, API_LIMIT, search)
            .then((result) => {
              setProducts(result);
            })
            .catch((err) => console.error(err));
        }, 600);
      }
    },
    [getAllProducts]
  );

  const reCalculateTotalsWithNewCif_BoughtPrices = useCallback((newCalcGroup: CalculationGroup) => {
    setCalculationGroup({
      ...newCalcGroup,

      oilsNonPercentageSum: newCalcGroup.isOilsPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.oils * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.oilsNonPercentageSum,
      customsNonPercentageSum: newCalcGroup.isCustomsPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.preCustoms * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.customsNonPercentageSum,
      stopajnonPercentageSum: newCalcGroup.isStopajPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.stopaj * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.stopajnonPercentageSum,
      KDVnonPercentageSum: newCalcGroup.isKDVPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.KKTC_KDV * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.KDVnonPercentageSum,
    });
  }, []);

  const reCalculatePercentagesWithNewCif_BoughtPrices = (newCalcGroup: CalculationGroup) => {
    const totalPrice = newCalcGroup.stockCalculations.map((st) => st.boughtAmount * st.boughtPriceAmount).reduce((a, b) => a + b, 0);

    const anotherCalcGroup = {
      ...newCalcGroup,

      stockCalculations: newCalcGroup.stockCalculations.map((calculation) => {
        const oils = newCalcGroup.isOilsPercentaged
          ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.oilsPercentage) / 100
          : (newCalcGroup.oilsNonPercentageSum * calculation.boughtPriceAmount) / totalPrice;
        const precustoms = newCalcGroup.isCustomsPercentaged
          ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.preCustomsPercentage) / 100
          : (newCalcGroup.customsNonPercentageSum * calculation.boughtPriceAmount) / totalPrice;
        return {
          ...calculation,
          //IF İTS PERCENTAGED THE PERCENTAGE VALUE DOESNT CHANGE, THE NUMERİC VALUE CHANGES.
          oils: oils,

          preCustoms: precustoms,

          stopaj: newCalcGroup.isStopajPercentaged
            ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.stopajPercentage) / 100
            : (newCalcGroup.stopajnonPercentageSum * calculation.boughtPriceAmount) / totalPrice,

          KKTC_KDV: newCalcGroup.isKDVPercentaged
            ? (calculation.cif + calculation.boughtPriceAmount + oils + precustoms) * (calculation.KKTC_KDV_Percentage / 100)
            : (newCalcGroup.KDVnonPercentageSum * calculation.boughtPriceAmount) / totalPrice,

          otherExpensesPercentageShared: (Object.values(newCalcGroup.otherExpenses).reduce((a, b) => a + b, 0) * calculation.boughtPriceAmount) / totalPrice,
        };
      }),
    };
    reCalculateTotalsWithNewCif_BoughtPrices(anotherCalcGroup);
  };

  return (
    <div className={classes.calculationDetailsPageWrapper}>
      {calculationsLoading ? (
        <div className={classes.LoaderWrapper}>
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      ) : null}
      <div className={classes.backIcon} onClick={() => navigate(-1)}>
        <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 11V8L8 12L12 16V13H16V11H12Z" fill="var(--primary-color)" />
        </svg>
      </div>
      <div className={classes.header}>{CALCULATION_DETAILS_ROUTE.TITLE}</div>
      <div className={classes.calcsTopWrapper}>
        <div className={classes.nameNoteWrapper}>
          <h3>Firma - Tarih</h3>
          <input placeholder="İsim Giriniz..." onChange={(e) => setCalculationGroup({ ...calculationGroup, name: e.target.value })} type="text" value={calculationGroup.name} />
        </div>
        <div className={classes.doviz}>
          <div className={classes.totalValues}>
            <h4>Döviz Kuru</h4>

            <input
              placeholder="Kur Giriniz"
              type="number"
              step=".01"
              value={calculationGroup.currencyGeneral}
              onChange={(e) => {
                const inputValue = isNaN(parseFloat(e.target.value)) ? 1 : parseFloat(e.target.value);

                setCalculationGroup({
                  ...calculationGroup,
                  currencyGeneral: inputValue,
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    return { ...calculation, currency: inputValue };
                  }),
                });
              }}
            />
          </div>
        </div>

        <div className={classes.rSelectWrapper}>
          <h3>Ürün Ekleme</h3>
          <ReactSelect
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "var(--secondary-color)",
                primary: "var(--primary-color)",
              },
            })}
            className={classes.rSelect}
            options={products.map((product) => {
              return {
                value: product,
                label: `${product.code.toUpperCase()} : ${product.name.toUpperCase()} : ${product.brand ? product.brand.toUpperCase() : ""} ${
                  product.note ? " :" + product.note.toUpperCase() : ""
                } `,
              };
            })}
            noOptionsMessage={() => {
              if (productsLoading) {
                return "Yükleniyor...";
              }
              return "Ürün bulunamadı";
            }}
            placeholder="Arama yapabilmek için en az 3 karakter giriniz"
            onInputChange={(e) => searchProducts(e)}
            onChange={handleSelectedOptionChange}
          />
        </div>
        <div className={classes.nameNoteWrapper}>
          <h3>Not</h3>
          <textarea
            contentEditable
            placeholder="Not Giriniz..."
            onChange={(e) => setCalculationGroup({ ...calculationGroup, note: e.target.value })}
            value={calculationGroup.note}
          />
        </div>
      </div>

      <div className={classes.accordionExpensesCalculationWrapper}>
        <span className={classes.accordionExpenses}>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                </svg>
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {" "}
              <div className={classes.accordionExpensesHeader}>
                Diğer Giderler:{" "}
                {numberWithCommas(
                  Math.round(
                    calculationGroup.stockCalculations
                      .map((item) => {
                        return (
                          (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.otherExpensesPercentageShared + item.preCustoms + item.stopaj) * item.boughtAmount
                        );
                      })
                      .reduce((a, b) => a + b, 0) * 100
                  ) / 100
                )}{" "}
                ₺
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {" "}
              <div style={{ flexDirection: "column" }} className={classes.otherExpensesWrapper}>
                <div className={classes.otherExpensesTotal}>
                  Toplam:{" "}
                  {numberWithCommas(
                    Math.round(
                      calculationGroup.stockCalculations
                        .map((item) => {
                          return (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.preCustoms + item.stopaj) * item.boughtAmount;
                        })
                        .reduce((a, b) => a + b, 0) * 100
                    ) / 100
                  )}{" "}
                  ₺
                </div>
                <div className={classes.otherExpensesValueOther}>
                  <div>Ek Mesai</div>

                  <input
                    className={classes.otherExpensesInput}
                    type="number"
                    step=".01"
                    placeholder="Değer Giriniz"
                    value={calculationGroup.otherExpenses.extraWork}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, extraWork: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesValueOther}>
                  {" "}
                  <div>Taxi</div>
                  <input
                    step=".01"
                    type="number"
                    value={calculationGroup.otherExpenses.taxi}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, taxi: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesValueOther}>
                  <div>Pul Gideri</div>
                  <input
                    step=".01"
                    type="number"
                    value={calculationGroup.otherExpenses.stampExpenses}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, stampExpenses: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesValueOther}>
                  <div>Masraflar</div>
                  <input
                    step=".01"
                    type="number"
                    value={calculationGroup.otherExpenses.expenses}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, expenses: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesValueOther}>
                  <div>Essential</div>
                  <input
                    step=".01"
                    type="number"
                    value={calculationGroup.otherExpenses.essential}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, essential: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesValueOther}>
                  <div>Fark</div>
                  <input
                    step=".01"
                    type="number"
                    value={calculationGroup.otherExpenses.difference}
                    onChange={(e) => {
                      const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                      const newCalcGroup = {
                        ...calculationGroup,
                        otherExpenses: { ...calculationGroup.otherExpenses, difference: inputValue },
                      };
                      reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                    }}
                  />
                </div>
                <div className={classes.otherExpensesTotal}>
                  Toplam:{" "}
                  {numberWithCommas(
                    Math.round(
                      calculationGroup.stockCalculations
                        .map((item) => {
                          return (
                            (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.otherExpensesPercentageShared + item.preCustoms + item.stopaj) * item.boughtAmount
                          );
                        })
                        .reduce((a, b) => a + b, 0) * 100
                    ) / 100
                  )}{" "}
                  ₺
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </span>
        <div style={{ width: "55%" }} className={classes.accordionExpenses}>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                </svg>
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {" "}
              <div className={classes.accordionExpensesHeader}>
                El İle Giriş (Doğrulama):{" "}
                {Object.values(manuelCalculation.otherExpenses).reduce((a, b) => a + b, 0) +
                  manuelCalculation.customs +
                  manuelCalculation.kdv +
                  manuelCalculation.navlun +
                  manuelCalculation.oils +
                  manuelCalculation.ordino +
                  manuelCalculation.stopaj +
                  manuelCalculation.trBuy +
                  manuelCalculation.trDocument}{" "}
                ₺
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {" "}
              <div className={classes.manuelCalcGeneral}>
                <div className={classes.manuelCalcWrapper}>
                  <div className={classes.otherExpensesWrapper}>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Tr Alım</div>
                      <div>{totalBoughtPriceAmount} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.trBuy}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, trBuy: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      {" "}
                      <div className={classes.otherExpensesHeader}>Navlun</div>
                      <div>{calculationGroup.cifTotal.navlun} ₺</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.navlun}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, navlun: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Ordino</div>
                      <div>{calculationGroup.cifTotal.ordino} ₺</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.ordino}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, ordino: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Gümrük</div>
                      <div>{calculationGroup.customsNonPercentageSum} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.customs}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, customs: inputValue });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.otherExpensesWrapper}>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Yağlar</div>
                      <div>{calculationGroup.oilsNonPercentageSum} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.oils}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, oils: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Stopaj</div>
                      <div>{calculationGroup.stopajnonPercentageSum} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.stopaj}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, stopaj: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>KDV</div>
                      <div>{calculationGroup.KDVnonPercentageSum} ₺</div>

                      <input
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.kdv}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, kdv: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Ek Mesai</div>
                      <div>{calculationGroup.otherExpenses.extraWork} ₺</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.otherExpenses.extraWork}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, extraWork: inputValue } });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.otherExpensesWrapper}>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Taxi</div>
                      <div>{calculationGroup.otherExpenses.taxi} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.otherExpenses.taxi}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, taxi: inputValue } });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Pul Gideri</div>
                      <div>{calculationGroup.otherExpenses.stampExpenses} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.otherExpenses.stampExpenses}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, stampExpenses: inputValue } });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Masraflar</div>
                      <div>{calculationGroup.otherExpenses.expenses} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.otherExpenses.expenses}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, expenses: inputValue } });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Essential</div>
                      <div>{calculationGroup.otherExpenses.essential} ₺</div>

                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.otherExpenses.essential}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, essential: inputValue } });
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.otherExpensesWrapper}>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>TR İhracat</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.trDocument}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, trDocument: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Diğer</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.other1}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, other1: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Diğer</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.other2}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, other2: inputValue });
                        }}
                      />
                    </div>
                    <div className={classes.otherExpensesValueManuel}>
                      <div className={classes.otherExpensesHeader}>Diğer</div>
                      <input
                        step=".01"
                        className={classes.otherExpensesInput}
                        type="number"
                        placeholder="Değer Giriniz"
                        value={manuelCalculation.other3}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setManuelCalculation({ ...manuelCalculation, other3: inputValue });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.otherExpensesTotal}>
                  Toplam:{" "}
                  {Object.values(manuelCalculation.otherExpenses).reduce((a, b) => a + b, 0) +
                    manuelCalculation.customs +
                    manuelCalculation.kdv +
                    manuelCalculation.navlun +
                    manuelCalculation.oils +
                    manuelCalculation.ordino +
                    manuelCalculation.stopaj +
                    manuelCalculation.trBuy +
                    manuelCalculation.trDocument}{" "}
                  ₺
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div>
        <div style={{ border: "none" }} className={classes.totalValuesWrapper}>
          <div className={classes.totalValues}>
            <h4>Cif Toplam</h4>
            <div className={classes.navlunOrdino}>
              <div>
                <div className={classes.navlunOrdinoHeader}>Navlun</div>
                <input
                  step=".01"
                  placeholder="Navlun Giriniz"
                  type="number"
                  value={calculationGroup.cifTotal.navlun}
                  onChange={(e) => {
                    const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                    const newCalcGroup = {
                      ...calculationGroup,
                      cifTotal: { ...calculationGroup.cifTotal, navlun: inputValue },

                      //Sharing the value among the products
                      stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                        return {
                          ...calculation,
                          cif: (inputValue + calculationGroup.cifTotal.ordino) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                        };
                      }),
                    };
                    reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                  }}
                />
              </div>
              <div>
                <div className={classes.navlunOrdinoHeader}>Ordino</div>
                <input
                  step=".01"
                  placeholder="Ordino Giriniz"
                  value={calculationGroup.cifTotal.ordino}
                  type="number"
                  onChange={(e) => {
                    const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

                    const newCalcGroup = {
                      ...calculationGroup,
                      cifTotal: { ...calculationGroup.cifTotal, ordino: inputValue },

                      //Sharing the value among the products
                      stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                        return {
                          ...calculation,
                          cif: (inputValue + calculationGroup.cifTotal.navlun) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                        };
                      }),
                    };
                    reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.mainMenuWrapper}>
          <div className={classes.totalValuesWrapper}>
            <div className={classes.totalValues}>
              <h4>
                Gümrük
                <span className={classes.orderPartFiltersWrapper}>
                  <input
                    type="checkbox"
                    checked={calculationGroup.isCustomsPercentaged}
                    id="CUSTOMS_PERCENTAGE"
                    onChange={() => {
                      changeCustoms();
                    }}
                  />
                  <label htmlFor="CUSTOMS_PERCENTAGE">Yüzdelik</label>
                  <input type="checkbox" checked={!calculationGroup.isCustomsPercentaged} onChange={() => changeCustoms()} id="CUSTOMS_SUM" />
                  <label htmlFor="CUSTOMS_SUM">Değer</label>
                </span>
              </h4>

              <input
                step=".01"
                placeholder="Değer Giriniz"
                type="number"
                disabled={calculationGroup.isCustomsPercentaged}
                value={Math.round(calculationGroup.customsNonPercentageSum * 100) / 100}
                onChange={(e) => {
                  const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

                  setCalculationGroup({
                    ...calculationGroup,
                    customsNonPercentageSum: parseFloat(e.target.value),
                    //Sharing the value among the products
                    stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                      const customs = inputValue * (calculation.boughtPriceAmount / totalBoughtPriceAmount);
                      return {
                        ...calculation,
                        preCustoms: customs,
                        KKTC_KDV: calculationGroup.isKDVPercentaged
                          ? (calculation.cif + calculation.boughtPriceAmount + calculation.oils + customs) * (calculation.KKTC_KDV_Percentage / 100)
                          : calculation.KKTC_KDV,
                      };
                    }),
                  });
                }}
              />
            </div>
            <div className={classes.totalValues}>
              <h4>
                Yağlar
                <span className={classes.orderPartFiltersWrapper}>
                  <input type="checkbox" checked={calculationGroup.isOilsPercentaged} id="OILS_PERCENTAGE" onChange={() => changeOils()} />
                  <label htmlFor="OILS_PERCENTAGE">Yüzdelik</label>
                  <input type="checkbox" checked={!calculationGroup.isOilsPercentaged} onChange={() => changeOils()} id="OILS_SUM" />
                  <label htmlFor="OILS_SUM">Değer</label>
                </span>
              </h4>

              <input
                step=".01"
                placeholder="Kur Giriniz"
                type="number"
                disabled={calculationGroup.isOilsPercentaged}
                value={Math.round(calculationGroup.oilsNonPercentageSum * 100) / 100}
                onChange={(e) => {
                  const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                  setCalculationGroup({
                    ...calculationGroup,
                    oilsNonPercentageSum: parseFloat(e.target.value),
                    //Sharing the value among the products

                    stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                      const oils = inputValue * (calculation.boughtPriceAmount / totalBoughtPriceAmount);
                      return {
                        ...calculation,
                        oils: oils,
                        KKTC_KDV: calculationGroup.isKDVPercentaged
                          ? (calculation.cif + calculation.boughtPriceAmount + calculation.preCustoms + oils) * (calculation.KKTC_KDV_Percentage / 100)
                          : calculation.KKTC_KDV,
                      };
                    }),
                  });
                }}
              />
            </div>
            <div className={classes.totalValues}>
              <h4>
                Stopaj
                <span className={classes.orderPartFiltersWrapper}>
                  <input type="checkbox" checked={calculationGroup.isStopajPercentaged} id="STOPAJ_PERCENTAGE" onChange={() => changeStopaj()} />
                  <label htmlFor="STOPAJ_PERCENTAGE">Yüzdelik</label>
                  <input type="checkbox" checked={!calculationGroup.isStopajPercentaged} onChange={() => changeStopaj()} id="STOPAJ_SUM" />
                  <label htmlFor="STOPAJ_SUM">Değer</label>
                </span>
              </h4>

              <input
                step=".01"
                placeholder="Kur Giriniz"
                type="number"
                disabled={calculationGroup.isStopajPercentaged}
                value={Math.round(calculationGroup.stopajnonPercentageSum * 100) / 100}
                onChange={(e) => {
                  const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                  setCalculationGroup({
                    ...calculationGroup,
                    stopajnonPercentageSum: parseFloat(e.target.value),
                    //Sharing the value among the products
                    stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                      return { ...calculation, stopaj: inputValue * (calculation.boughtPriceAmount / totalBoughtPriceAmount) };
                    }),
                  });
                }}
              />
            </div>

            <div className={classes.totalValues}>
              <h4>
                KDV
                <span className={classes.orderPartFiltersWrapper}>
                  <input type="checkbox" checked={calculationGroup.isKDVPercentaged} id="KDV_PERCENTAGE" onChange={() => changeKDV()} />
                  <label htmlFor="KDV_PERCENTAGE">Yüzdelik</label>
                  <input type="checkbox" checked={!calculationGroup.isKDVPercentaged} onChange={() => changeKDV()} id="KDV_SUM" />
                  <label htmlFor="KDV_SUM">Değer</label>
                </span>
              </h4>

              <input
                placeholder="KDV Giriniz"
                type="number"
                value={Math.round(calculationGroup.KDVnonPercentageSum * 100) / 100}
                disabled={calculationGroup.isKDVPercentaged}
                onChange={(e) => {
                  const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

                  setCalculationGroup({
                    ...calculationGroup,
                    KDVnonPercentageSum: parseFloat(e.target.value),
                    //Sharing the value among the products
                    stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                      return { ...calculation, KKTC_KDV: inputValue * ((calculation.boughtPriceAmount * calculation.boughtAmount) / totalBoughtPriceAmount) };
                    }),
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.calculationsButtonWrapper}>
        <button
          className={classes.calculationSaveButton}
          onClick={() => {
            const newCalcGroup = {
              ...calculationGroup,
              stockCalculations: calculationGroup.stockCalculations.map((c, i) => {
                c.pricePerAmount =
                  (((c.KKTC_KDV + c.boughtPriceAmount + c.cif + c.oils + c.otherExpensesPercentageShared + c.preCustoms) / c.currency) * (c.salesListPercentage + 100)) / 100;

                return c;
              }),
            };
            if (calculationGroup._stockCalculationId === 0) {
              postCalculation(newCalcGroup).then((res) => {
                setCalculationGroup({
                  ...newCalcGroup,
                  _stockCalculationId: res._stockCalculationId,
                  stockCalculations: newCalcGroup.stockCalculations.map((c, index) => {
                    c._calcId = res.stockCalculations[index]._calcId;
                    return c;
                  }),
                });
              });
            } else {
              updateCalculation(newCalcGroup);
            }
          }}
          disabled={calculationGroup.name === "" || calculationGroup.note === ""}
        >
          Kaydet
        </button>
        <button className={classes.calculationCancelButton} onClick={() => navigate(-1)}>
          İptal
        </button>
      </div>
      {calculationGroup.stockCalculations.length !== 0 ? (
        <div className={classes.accordionList}>
          <h2>Ürünler</h2>
          {calculationGroup.stockCalculations.map((calculation, index) => {
            return (
              <Accordion style={{ backgroundColor: calculation.status === "CLOSED" ? "#696766" : "" }} className={classes.accordionWrapper}>
                <AccordionSummary
                  expandIcon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                    </svg>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {" "}
                  <div className={classes.accordionHeader}>
                    {`${calculation.productCode.toUpperCase()} : ${calculation.productName.toUpperCase()} : ${
                      calculation.productBrand ? calculation.productBrand.toUpperCase() : ""
                    } ${calculation.productNote ? " :" + calculation.productNote.toUpperCase() : ""} `}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ borderTop: "2px solid var(--secondary-color)", paddingTop: "2rem" }} className={classes.totalValuesAccordionWrapper}>
                    <div className={classes.totalValues}>
                      <h4>Alınan Adet</h4>
                      <input
                        className={classes.calculationProductInput}
                        placeholder="Adet Giriniz"
                        type="number"
                        disabled={calculation.status === "CLOSED"}
                        value={calculation.boughtAmount}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              return {
                                ...calc,
                                boughtAmount: i === index ? inputValue : calc.boughtAmount,
                                cif:
                                  ((calculationGroup.cifTotal.navlun + calculationGroup.cifTotal.ordino) * calc.boughtPriceAmount) /
                                  (totalBoughtPriceAmount + calculation.boughtPriceAmount * (inputValue - calculation.boughtAmount)),
                              };
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Alım Birim Fiyat</h4>
                      <input
                        step=".01"
                        className={classes.calculationProductInput}
                        disabled={calculation.status === "CLOSED"}
                        placeholder="Fiyat Giriniz"
                        type="number"
                        value={calculation.boughtPriceAmount}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              return {
                                ...calc,
                                boughtPriceAmount: i === index ? inputValue : calc.boughtPriceAmount,
                                cif:
                                  ((calculationGroup.cifTotal.navlun + calculationGroup.cifTotal.ordino) * (i === index ? inputValue : calc.boughtPriceAmount)) /
                                  (totalBoughtPriceAmount + calculation.boughtAmount * (inputValue - calculation.boughtPriceAmount)),
                              };
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                    </div>

                    <div className={classes.totalValues}>
                      <h4>Yağlar %</h4>
                      <input
                        className={classes.calculationProductInput}
                        disabled={!calculationGroup.isOilsPercentaged || calculation.status === "CLOSED"}
                        placeholder="Yüzde Değeri Giriniz"
                        type="number"
                        value={calculation.oilsPercentage}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.oilsPercentage = inputValue;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <p>{numberWithCommas(Math.round(calculation.oils * 100) / 100)} ₺</p>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Gümrük %</h4>
                      <input
                        className={classes.calculationProductInput}
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isCustomsPercentaged || calculation.status === "CLOSED"}
                        type="number"
                        value={calculation.preCustomsPercentage}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.preCustomsPercentage = inputValue;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <p>{numberWithCommas(Math.round(calculation.preCustoms * 100) / 100)} ₺</p>
                    </div>

                    <div className={classes.totalValues}>
                      <h4>Stopaj %</h4>
                      <input
                        className={classes.calculationProductInput}
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isStopajPercentaged || calculation.status === "CLOSED"}
                        value={calculation.stopajPercentage}
                        type="number"
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);

                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.stopajPercentage = inputValue;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <p>{numberWithCommas(Math.round(calculation.stopaj * 100) / 100)} ₺</p>
                    </div>

                    <div className={classes.totalValues}>
                      <h4>KDV %</h4>
                      <input
                        className={classes.calculationProductInput}
                        step=".01"
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isKDVPercentaged || calculation.status === "CLOSED"}
                        type="number"
                        value={calculation.KKTC_KDV_Percentage}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.KKTC_KDV_Percentage = inputValue;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <p>{numberWithCommas(Math.round(calculation.KKTC_KDV * 100) / 100)} ₺</p>
                    </div>
                    {/*                     <div style={{ width: "10%" }} className={classes.totalValues}>
                      <h4>Cif</h4>
                      <p>{numberWithCommas(Math.round(calculation.cif * 100) / 100)}</p>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Diğer Giderler</h4>
                      <p>{numberWithCommas(Math.round(calculation.otherExpensesPercentageShared * 100) / 100)}</p>
                    </div> */}
                    <div className={classes.totalValues}>
                      <h4>Maliyet Birim €</h4>
                      <p>
                        {numberWithCommas(
                          Math.round(
                            ((calculation.KKTC_KDV +
                              calculation.boughtPriceAmount +
                              calculation.cif +
                              calculation.oils +
                              calculation.stopaj +
                              calculation.otherExpensesPercentageShared +
                              calculation.preCustoms) /
                              calculation.currency) *
                              100
                          ) / 100
                        )}{" "}
                        €
                      </p>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Kar yüzdesi</h4>
                      <input
                        className={classes.calculationProductInput}
                        disabled={calculation.status === "CLOSED"}
                        step=".01"
                        placeholder="Yüzde Giriniz"
                        type="number"
                        value={calculation.salesListPercentage}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setCalculationGroup({
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calculation, i) => {
                              if (i === index) {
                                return { ...calculation, salesListPercentage: inputValue };
                              }
                              return calculation;
                            }),
                          });
                        }}
                      />
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Liste Birim €</h4>
                      <p>
                        {numberWithCommas(
                          Math.round(
                            ((((calculation.KKTC_KDV +
                              calculation.boughtPriceAmount +
                              calculation.cif +
                              calculation.oils +
                              calculation.otherExpensesPercentageShared +
                              calculation.stopaj +
                              calculation.preCustoms) /
                              calculation.currency) *
                              (calculation.salesListPercentage + 100)) /
                              100) *
                              100
                          ) / 100
                        )}{" "}
                        €
                      </p>
                    </div>
                  </div>
                  <div className={classes.totalValuesWrapper}>
                    <div className={classes.totalValues}>
                      <h4>Döviz Kuru</h4>
                      <input
                        className={classes.calculationProductInput}
                        step=".01"
                        disabled={calculation.status === "CLOSED"}
                        placeholder="Döviz Kuru Giriniz"
                        type="number"
                        value={calculationGroup.stockCalculations[index].currency}
                        onChange={(e) => {
                          const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                          setCalculationGroup({
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calculation, i) => {
                              if (i === index) {
                                return { ...calculation, currency: inputValue };
                              }
                              return calculation;
                            }),
                          });
                        }}
                      />
                    </div>
                    <button
                      className={classes.sendStockButton}
                      onClick={() => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          stockCalculations: calculationGroup.stockCalculations.map((c, i) => {
                            c.pricePerAmount =
                              (((c.KKTC_KDV + c.boughtPriceAmount + c.cif + c.oils + c.otherExpensesPercentageShared + c.preCustoms) / c.currency) *
                                (c.salesListPercentage + 100)) /
                              100;
                            if (i === index) {
                              c.status = "CLOSED";
                            }
                            return c;
                          }),
                        };
                        setCalculationGroup(newCalcGroup);
                        if (calculationGroup._stockCalculationId === 0) {
                          postCalculation(calculationGroup).then((res) => {
                            setCalculationGroup({
                              ...calculationGroup,
                              _stockCalculationId: res._stockCalculationId,
                              stockCalculations: calculationGroup.stockCalculations.map((c, index) => {
                                c._calcId = res.stockCalculations[index]._calcId;
                                return c;
                              }),
                            });
                          });
                        } else {
                          updateCalculation(newCalcGroup).then((res) =>
                            setCalculationGroup({
                              ...calculationGroup,
                              _stockCalculationId: res._stockCalculationId,
                              stockCalculations: calculationGroup.stockCalculations.map((c, index) => {
                                c._calcId = res.stockCalculations[index]._calcId;
                                return c;
                              }),
                            })
                          );
                        }
                      }}
                      disabled={calculation.status === "CLOSED" || calculationGroup.name === "" || calculationGroup.note === ""}
                    >
                      {calculation.status === "CLOSED" ? "Stokta" : "Stoğa Gönder"}
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
