import { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar/Navbar";

import { ThreeCircles } from "react-loader-spinner";
import classes from "./StockCalculationsPage.module.scss";
import { CALCULATION_DETAILS_ROUTE, STOCK_CALCULATIONS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";

import { useNavigate } from "react-router";
import useCalculation from "../../hooks/useCalculation";
import { CalculationGeneral } from "../../Models/CalculationGeneral";
import { StockCalculationCard } from "../../Components/StockCalculationCard/StockCalculationCard";

export const StockCalculationsPage = () => {
  const [stockCalculations, setStockCalculations] = useState<CalculationGeneral[]>([]);

  const navigate = useNavigate();

  const { getCalculations, calculationsLoading } = useCalculation();

  useEffect(() => {
    getCalculations().then((calcs) => setStockCalculations(calcs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <div className={classes.stockCalculationsWrapper}>
        <div className={classes.stockHeaderWrapper}>
          <h1 className={classes.stockHeader}>{STOCK_CALCULATIONS_ROUTE.TITLE}</h1>
        </div>
        <span className={classes.stockCalculationsButtonsWrapper}>
          <span>
            <button
              onClick={() => {
                navigate(`${CALCULATION_DETAILS_ROUTE.PATH}/0`);
              }}
            >
              Yeni Alım Hesabı Aç
            </button>
          </span>
          {/*           <span className={classes.stockCalculationsFiltersWrapper}>
            <input type="checkbox" checked={filter === "OPEN"} id="OPEN_FILTER" onChange={() => setFilter("OPEN")} />
            <label htmlFor="OPEN_FILTER">Açık Alımlar</label>

            <input type="checkbox" checked={filter === "CLOSED"} id="CLOSED_FILTER" onChange={() => setFilter("CLOSED")} />
            <label htmlFor="CLOSED_FILTER">Kapalı Alımlar</label>
          </span> */}
        </span>
        {calculationsLoading ? <ThreeCircles height="60" width="60" color="var(--primary-color)" /> : null}
        <div className={classes.calculationCardsWrapper}>
          {stockCalculations.map((calculation) => {
            return <StockCalculationCard stockCalculation={calculation} />;
          })}
        </div>
      </div>
    </div>
  );
};
